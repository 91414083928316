import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logoog.webp";
import { profileFetchData } from "../../redux/actions/commonAction";
import { searchValue } from "../../redux/actions/searchValue";
import HelperScript from "../../utils/helper";
import Login from "../pages/Login";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FaUser, FaPowerOff, FaSearch, FaMapMarkerAlt } from "react-icons/fa";

const Header = (props) => {
  const [loginStateEnable, setLoginEnableState] = useState(false);
  const [userName, setUserName] = useState("");
  const [login, setlogin] = useState("");
  const tithi = useSelector((state) => state?.TithiReducer);
  const showLoginModel = () => {
    setLoginEnableState(!loginStateEnable);
  };
  let navigate = useNavigate();

  let dispatch = useDispatch();
  var months = [
    "જાન્યુઆરી",
    "ફેબ્રુઆરી",
    "માર્ચ",
    "એપ્રિલ",
    "મે",
    "જૂન",
    "જુલાઈ",
    "ઓગસ્ટ",
    "સપ્ટેમ્બર",
    "ઓક્ટોબર",
    "નવેમ્બર",
    "ડિસેમ્બર",
  ];
  var now = new Date();
  var thisMonth = months[now.getMonth()];

  var newdate = new Date();
  var thisDate = newdate.getDate();

  var days = [
    "રવિવાર",
    "સોમવાર",
    "મંગળવાર",
    "બુધવાર",
    "ગુરૂવાર",
    "શુક્રવાર",
    "શનિવાર",
  ];
  var newday = new Date();
  var thisDay = days[newday.getDay()];

  var year = new Date();
  var thisYear = year.getFullYear();

  useEffect(() => {
    const timerId = setTimeout(checklocation, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  const [inputSearchValue, setInputSearchValue] = useState("");

  const checklocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    }
  };

  function successFunction(position) {
    const lat = position.coords.latitude;
    const long = position.coords.longitude;
    localStorage.setItem(
      "SandeshLocation",
      JSON.stringify({ lat: lat, long: long })
    );
    const locationCheck = document.getElementById("locationCheck");
    if (locationCheck) locationCheck.style.display = "none";
  }

  function errorFunction(err) {
    const locationCheck = document.getElementById("locationCheck");
    if (locationCheck) locationCheck.style.display = "none";
  }

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(searchValue(inputSearchValue));
      if (inputSearchValue) {
        navigate("/search");
      }
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, [inputSearchValue]);

  const Logout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("access_token");
    localStorage.removeItem("userID");
    localStorage.removeItem("socialLogin");
    setUserName("");
    setlogin("clear");
    if (window.location.pathname === "/profile") {
      navigate("/");
    } else {
      navigate(window.location.pathname);
    }
  };
  if (localStorage.getItem("access_token") !== null) {
    dispatch(profileFetchData()).then((res) => {
      if (res) {
        setUserName(res?.user_data?.name);
      } else {
        setUserName("");
      }
    });
  }
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      setlogin("success");
    } else {
      setlogin("clear");
    }
  });

  const success = (success) => {
    setlogin(success);
  };

  const headerweb =
    "" +
    "<div id='div-gpt-ad-1646031348333-0' style='min-width: 728px; min-height: 90px;'> " +
    "<script>" +
    "window.googletag = window.googletag || { cmd: [] };" +
    "googletag.cmd.push(function () {" +
    "googletag.defineSlot('/22678428348/Test_HP_header_728X90', [728, 90], 'div-gpt-ad-1646031348333-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.pubads().disableInitialLoad();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "" +
    "<script>" +
    "  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646031348333-0'); });" +
    "</script>" +
    "</div>" +
    "" +
    "";

  const [showSearch, setShowSearch] = useState(false);

  const isLoggedIn =
    login === "success" && localStorage.getItem("access_token");
  return (
    <>
      <div className="text-center">
        <div className="ad-section">
          <HelperScript addstyle={1} argSmall={164} argLarge={2} />
        </div>
      </div>
      <section className="header">
        <div className="topbar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="time" id="demo">
                      <span className="date-top-header">
                        {thisDay}, {thisDate} {thisMonth}, {thisYear}{" "}
                      </span>
                      <span
                        className="ml-3"
                        dangerouslySetInnerHTML={{
                          __html: tithi?.data?.text,
                        }}
                      />
                    </div>
                    <a
                      href="/epaper"
                      target="_blank"
                      className="e-paper-header"
                    >
                      E-PAPER
                    </a>
                    <div className="contacts"></div>
                  </div>
                  <div className="rightbar">
                    <ul>
                      {isLoggedIn ? (
                        <>
                          <li>
                            <Link
                              to="/profile"
                              className="signin cursor-pointer"
                            >
                              <FaUser />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/profile"
                              className="signin cursor-pointer"
                            >
                              Hi, {userName?.split(" ")[0] || "There"}
                            </Link>
                          </li>
                          <li>
                            <FaPowerOff
                              onClick={Logout}
                              style={{
                                fontSize: "18px",
                                padding: "9px",
                                cursor: "pointer",
                              }}
                            />
                          </li>
                        </>
                      ) : (
                        <li>
                          <a
                            onClick={showLoginModel}
                            className="signin cursor-pointer"
                          >
                            <FaUser />
                          </a>
                        </li>
                      )}

                      <li>
                        <a onClick={checklocation} className="location">
                          <FaMapMarkerAlt />
                        </a>
                      </li>

                      <li>
                        <div className="d-flex">
                          {showSearch && (
                            <input
                              value={inputSearchValue}
                              onChange={(e) =>
                                setInputSearchValue(e.target.value)
                              }
                              type="search"
                              className="form-control border-0 bg-light"
                              placeholder="Search"
                            />
                          )}
                          <FaSearch
                            onClick={() => setShowSearch((prev) => !prev)}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="logo">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <a href="/" className="brand">
                  {/* <LazyLoadImage
                    
                    src={logo}
                    style={{ filter: "none", width: "263px" }}
                  /> */}
                  <link rel="preload" href={logo} as="image" />

                  {/* Logo image with best practices */}
                  <img
                    src={logo}
                    alt="Brand Logo"
                    width="263"
                    height="auto"
                    fetchpriority="high" // Ensures the image is fetched first
                    loading="eager" // Ensures eager loading of the image
                    decoding="async" // Decodes the image asynchronously to avoid blocking rendering
                  />
                </a>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div
                    className="col-md-10 ad-section"
                    style={{ marginRight: "auto", marginLeft: "-23px" }}
                  >
                    <HelperScript
                      argSmall={165}
                      argLarge={3}
                      key={window.location.pathname}
                    />
                  </div>
                  <div className="col-md-2 qr-code-app text-center">
                    <a href="/d" target="_blank">
                      <img
                        loading="lazy"
                        src={require("../../assets/qrcode-app.png")}
                        alt=""
                      />
                    </a>
                    <a href="/d" target="_blank">
                      <span>Download App</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {loginStateEnable && <Login success={success} />}
    </>
  );
};

export default Header;
