import "./App.css";
import "./styles/custom.css";
import "./styles/fa.css";
import React, { lazy, useEffect, Suspense, useMemo } from "react";
import $ from "jquery";
import Topbar from "./components/component/Topbar";
import ScrollToTop from "./ScrollToTop";
import HelperScript from "./utils/helper";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";
import InjectScript from "./components/CustomScript";
import { marqueeFetchData } from "./redux/actions/marqueeAction";
import { Helmet } from "react-helmet";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { menuFetchData } from "./redux/actions/menuAction";
import { childmenuFetchData } from "./redux/actions/childmenuAction";
import { tithiFetchData } from "./redux/actions/tithiAction";
import { widgetFetchData } from "./redux/actions/widgetAction";
import { horoscopeFetchData } from "./redux/actions/horoscopeAction";
import { footerFetchData } from "./redux/actions/footerAction";
import { settingFetchData } from "./redux/actions/settingAction";
import { categoryAstroFetchData } from "./redux/actions/categoryAstroAction";
import { useDispatch, useSelector } from "react-redux";

const Home = lazy(() => import("./components/pages/Home"));
const Menu = lazy(() => import("./components/subcomponents/Menu"));
const MenuPage = lazy(() => import("./components/subcomponents/MenuPage"));
const BreadCrumb = lazy(() => import("./components/component/BreadCrumb"));
const ScrollButton = lazy(() => import("./components/pages/ScrollButton"));
const Gujarat = lazy(() => import("./components/pages/Gujarat"));
const Rss = lazy(() => import("./components/pages/Rss"));
const Post = lazy(() => import("./components/pages/Post"));
const Sitemap = lazy(() => import("./components/pages/Sitemap"));
const Epaper = lazy(() => import("./components/pages/Epaper/Epaper"));

const ClippedImage = lazy(() =>
  import("./components/pages/Epaper/ClippedImage")
);
const EpaperCategoryNewsPerDate = lazy(() =>
  import("./components/pages/Epaper/EpaperCategoryNewsPerDate")
);
const Pages = lazy(() => import("./components/pages/Pages"));
const VideosComponent = lazy(() =>
  import("./components/pages/VideosComponent")
);
const PagesInvestorRelation = lazy(() =>
  import("./components/pages/PagesInvestorRelation")
);
const GalleryComponent = lazy(() =>
  import("./components/pages/GalleryComponent")
);
const AstrologyComponent = lazy(() =>
  import("./components/pages/AstrologyComponent")
);
const SearchResult = lazy(() => import("./components/pages/SearchResult"));
const Profile = lazy(() => import("./components/pages/Profile"));
const Maintenance = lazy(() => import("./components/pages/maintenance"));
const Supplement = lazy(() => import("./components/pages/Supplement"));
const VideoCategoryPage = lazy(() =>
  import("./components/pages/VideoCategoryPage")
);
const GujaratMetro = lazy(() => import("./components/pages/GujaratMetro"));
const GujaratSamagra = lazy(() => import("./components/pages/GujaratSamagra"));
const Sport = lazy(() => import("./components/pages/Sport"));
const AssemblyElection = lazy(() =>
  import("./components/pages/AssemblyElection")
);
const GaneshChaturthiPage = lazy(() =>
  import("./components/pages/GaneshChaturthiPage")
);
const Footer = lazy(() => import("./components/subcomponents/Footer"));

const EpaperMenu = lazy(() => import("./components/pages/Epaper/EpaperMenu"));

function App() {
  const [mode, setMode] = React.useState("light");
  const [url, setUrl] = React.useState(window.location.href);
  const [sport, setSport] = React.useState(false);
  // const [load, setLoad] = React.useState(false);
  // setTimeout(() => {
  //   setLoad(true);
  // }, [5000]);
  const { 50: sportLayout } = useSelector(
    (state) => state?.SettingReducer?.data
  );
  const onDarkMode = () => {
    document.documentElement.classList.toggle("dark-mode");
    setMode(mode === "dark" ? "light" : "dark");
  };

  Sentry.init({
    dsn: "https://9fbd68e8996640c294f72b3d924bf7cf@o1158838.ingest.sentry.io/6242283",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  ReactGA.initialize("G-6YPHENN7FN", {
    titleCase: false,
  });

  useEffect(() => {
    setInterval(() => {
      setUrl(window.location.href);
    }, 300);
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(settingFetchData());
    const timer = setTimeout(() => {
      dispatch(menuFetchData());
      dispatch(childmenuFetchData());
      dispatch(tithiFetchData());
      dispatch(widgetFetchData());
      dispatch(footerFetchData());
      dispatch(horoscopeFetchData());
      dispatch(categoryAstroFetchData());
      dispatch(marqueeFetchData());
    }, 350);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const routeChange = () => {};

  useEffect(() => {
    $("section.menu .nav-item a").each(function () {
      $(this).removeClass("act");
      if (window.location.pathname === $(this).attr("href")) {
        $(this).addClass("act");
      }
    });
    $(".navbar-toggler").addClass("collapsed").attr("aria-expanded", "false");
    $(".navbar-collapse").removeClass("show");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.href]);

  const taboolacontent =
    "" +
    "<script>" +
    "window._taboola = window._taboola || [];" +
    "_taboola.push({notify:'newPageLoad'});" +
    "_taboola.push({article:'auto', url: '" +
    window.location.href +
    "'});" +
    // "_taboola.push({category:'auto', url: '" + window.location.href + "'});" +
    // "_taboola.push({homepage:'auto', url: '" + window.location.href + "'});" +
    "</script>" +
    "";

  const headerMobile =
    "" +
    "<div id='div-gpt-ad-1646996478869-0' class='ad-placeholder' style='min-width: 300px; min-height: 100px;'>" +
    "<script>" +
    "window.googletag = window.googletag || { cmd: [] };" +
    "googletag.cmd.push(function () {" +
    "googletag.defineSlot('/22678428348/Mweb_Header_Global_300x100', [300, 100], 'div-gpt-ad-1646996478869-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.pubads().disableInitialLoad();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "" +
    "<script>" +
    " googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646996478869-0'); });" +
    "</script>" +
    "</div>" +
    // '<div id="gpt-passback">' +
    // "<script>" +
    // "window.googletag = window.googletag || {cmd: []};" +
    // "googletag.cmd.push(function() {" +
    // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x100', [300, 100], 'gpt-passback').addService(googletag.pubads());" +
    // "googletag.enableServices();" +
    // "googletag.display('gpt-passback');" +
    // "});" +
    // "</script>" +
    // "</div>" +
    "" +
    "";

  const allPage =
    "" +
    '<div id="bsrv1338" data-domain="sandesh.com" data-click-tracker="%%CLICK_TRACKER%%">' +
    '<script src="https://bsrv.bidsxchange.com/1338.js"></script>' +
    "</div>" +
    '<script src="https://bsrv-qa.bidsxchange.com/1338.js"></script>' +
    "";

  const bottomFooterScript =
    "" +
    "<script>" +
    "window.googletag = window.googletag || { cmd: [] };" +
    "var ub_interstitialSlot;" +
    "googletag.cmd.push(function () {" +
    'ub_interstitialSlot = googletag.defineOutOfPageSlot("/22678428348/SDPL_Epaper_Interstitial",googletag.enums.OutOfPageFormat.INTERSTITIAL).addService(googletag.pubads());' +
    "googletag.enableServices();});" +
    "googletag.cmd.push(function () {googletag.pubads().refresh([ub_interstitialSlot]);});" +
    "</script>" +
    "";

  const homePageScript2nd =
    "" +
    '<script type="text/javascript">' +
    "(function(){" +
    "var o='script'," +
    "s=top.document," +
    "a=s.createElement(o)," +
    "m=s.getElementsByTagName(o)[0]," +
    "d=new Date()," +
    'timestamp=""+d.getDate()+d.getMonth()+d.getHours();' +
    "a.async=1;" +
    "a.src='https://cdn4-hbs.affinitymatrix.com/hvrcnf/sandesh.com/'+ timestamp + '/index?t='+timestamp;" +
    "m.parentNode.insertBefore(a,m)" +
    "})();" +
    "</script>" +
    "";
  setTimeout(() => {
    setSport(sportLayout?.value);
  }, 200);

  const globalScript =
    "" +
    // '<script async src="https://cdn.unibots.in/genericcube/common/script.js"></script>'+
    // "<script>"+
    //   "window.unibotsDirectAds = window.unibotsDirectAds || { cmd: [] };"+
    //    "unibotsDirectAds.cmd.push('sandesh');"+
    // "</script>"+
    "";

  const homePageScript4th =
    "" +
    "<div id='div-gpt-ad-1666261240163-0'>" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1666261240163-0'); });" +
    "</script>" +
    " <script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/Median_CanadaCampaign_1x1', [1, 1], 'div-gpt-ad-1666261240163-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    " googletag.enableServices();" +
    "});" +
    "</script>" +
    "</div>" +
    "";

  //   const New_add_vertical = `
  //   <div id='div-gpt-ad-1739336906750-0'>
  //   <script>
  //   window.googletag = window.googletag || {cmd: []};
  //   googletag.cmd.push(function() {
  //     googletag.defineSlot('/22678428348/SDPL_HP_1x1_Vertice', [1, 1], 'div-gpt-ad-1739336906750-0').addService(googletag.pubads());
  //     googletag.pubads().enableSingleRequest();
  //     googletag.enableServices();
  //   });
  // </script>
  // <script>
  //     googletag.cmd.push(function() { googletag.display('div-gpt-ad-1739336906750-0'); });
  //   </script>
  //   </div>
  //   `;

  return (
    <>
      <div className="ad-section">
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "NewsMediaOrganization",
              name: "સંદેશ",

              alternateName: "Sandesh",
              url: "https://sandesh.com/",
              logo: "https://epapercdn.sandesh.com/images/2022/03/08/6Qe51vSYmRDNtjo3tKdg2fdL6DTrvaSb7ufSfOG4.jpg",
              sameAs: [
                "https://www.facebook.com/sandeshnewspaper",
                "https://x.com/sandeshnews",
                "https://www.instagram.com/sandeshnews/",
                "https://www.youtube.com/user/SandeshNewsTV",
                "https://sandesh.com/",
              ],
            })}
          </script>
        </Helmet>

        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "NewsArticle",
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": "https://sandesh.com/",
              },
              headline:
                "Gujarati News | Breaking News in Gujarati | ગુજરાતી સમાચાર | Sandesh",
              description:
                "Sandesh is one of the leading Gujarati News paper. Get all latest news, ગુજરાતી સમાચાર, breaking news in Gujarati about National, World, Sports, Entertainment, Elections, etc in Gujarati. અમે છીએ સત્ય ની સાથે, તમારી સાથે.",
              image: {
                "@type": "ImageObject",
                url: "https://epapercdn.sandesh.com/images/2022/03/08/6Qe51vSYmRDNtjo3tKdg2fdL6DTrvaSb7ufSfOG4.jpg",
                width: "",
                height: "",
              },
              author: {
                "@type": "Organization",
                name: "Sandesh",
              },
              publisher: {
                "@type": "Organization",
                name: "",
                logo: {
                  "@type": "ImageObject",
                  url: "",
                  width: "",
                  height: "",
                },
              },
              datePublished: "",
            })}
          </script>
        </Helmet>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "WebSite",
              name: "Sandesh",
              url: "https://sandesh.com/",
              potentialAction: {
                "@type": "SearchAction",
                target: "https://sandesh.com/search?q={search_term_string}",
                "query-input": "required name=search_term_string",
              },
            })}
          </script>
        </Helmet>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Homepage",
                  item: "https://sandesh.com/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Gujarat",
                  item: "https://sandesh.com/gujarat",
                },
                {
                  "@type": "ListItem",
                  position: 3,
                  name: "Agriculture",
                  item: "https://sandesh.com/agriculture",
                },
                {
                  "@type": "ListItem",
                  position: 4,
                  name: "Videos",
                  item: "https://sandesh.com/videos",
                },
                {
                  "@type": "ListItem",
                  position: 5,
                  name: "Sports",
                  item: "https://sandesh.com/sports",
                },
                {
                  "@type": "ListItem",
                  position: 6,
                  name: "Business",
                  item: "https://sandesh.com/business",
                },
                {
                  "@type": "ListItem",
                  position: 7,
                  name: "India",
                  item: "https://sandesh.com/india",
                },
                {
                  "@type": "ListItem",
                  position: 8,
                  name: "World",
                  item: "https://sandesh.com/world",
                },
                {
                  "@type": "ListItem",
                  position: 9,
                  name: "Entertainment",
                  item: "https://sandesh.com/entertainment",
                },
                {
                  "@type": "ListItem",
                  position: 10,
                  name: "Lifestyle",
                  item: "https://sandesh.com/lifestyle",
                },
                {
                  "@type": "ListItem",
                  position: 11,
                  name: "Knowledge",
                  item: "https://sandesh.com/knowledge",
                },
                {
                  "@type": "ListItem",
                  position: 12,
                  name: "Technology",
                  item: "https://sandesh.com/tech",
                },
                {
                  "@type": "ListItem",
                  position: 13,
                  name: "Crime",
                  item: "https://sandesh.com/crime",
                },
                {
                  "@type": "ListItem",
                  position: 14,
                  name: "Bhakti",
                  item: "https://sandesh.com/bhakti",
                },
                {
                  "@type": "ListItem",
                  position: 15,
                  name: "Astrology",
                  item: "https://sandesh.com/astrology",
                },
                {
                  "@type": "ListItem",
                  position: 16,
                  name: "Supplement",
                  item: "https://sandesh.com/supplement",
                },
                {
                  "@type": "ListItem",
                  position: 17,
                  name: "Eduscope",
                  item: "https://sandesh.com/edu-scope",
                },
                {
                  "@type": "ListItem",
                  position: 18,
                  name: "Business sandesh",
                  item: "https://sandesh.com/business-sandesh",
                },
                {
                  "@type": "ListItem",
                  position: 19,
                  name: "Nari",
                  item: "https://sandesh.com/nari",
                },
                {
                  "@type": "ListItem",
                  position: 20,
                  name: "Ardhasaptahik",
                  item: "https://sandesh.com/ardhasaptahik",
                },
                {
                  "@type": "ListItem",
                  position: 21,
                  name: "Shraddha",
                  item: "https://sandesh.com/shraddha",
                },
                {
                  "@type": "ListItem",
                  position: 22,
                  name: "Nakshatra",
                  item: "https://sandesh.com/nakshatra",
                },
                {
                  "@type": "ListItem",
                  position: 23,
                  name: "Cine sandesh",
                  item: "https://sandesh.com/cine-sandesh",
                },
                {
                  "@type": "ListItem",
                  position: 24,
                  name: "Kids world",
                  item: "https://sandesh.com/kids-world",
                },
                {
                  "@type": "ListItem",
                  position: 25,
                  name: "Sanskar",
                  item: "https://sandesh.com/sanskar",
                },
                {
                  "@type": "ListItem",
                  position: 26,
                  name: "State election 2024",
                  item: "https://sandesh.com/state-election-2024",
                },
                {
                  "@type": "ListItem",
                  position: 27,
                  name: "US election",
                  item: "https://sandesh.com/us-election-2024",
                },
                {
                  "@type": "ListItem",
                  position: 28,
                  name: "Flashback 2024",
                  item: "https://sandesh.com/flashback-2024",
                },
              ],
            })}
          </script>
        </Helmet>
        <div className="text-center">
          <HelperScript argSmall={187} argLarge={40} />
        </div>
      </div>

      <InjectScript key={Math.random()} script={taboolacontent} />
      <InjectScript script={globalScript} key={Math.random()} />
      {/* <InjectScript script={New_add_vertical} /> */}
      <Router onChange={routeChange}>
        <ScrollToTop />
        <Suspense fallback={<></>}>
          <Topbar onDarkMode={onDarkMode} mode={mode} />
          {window.location.href?.includes("/epaper") ? (
            <EpaperMenu />
          ) : (
            <>
              <Menu />
              <MenuPage />
              {window.location.href?.includes("/metro") ||
              window.location.href?.includes("/samagra") ||
              window.location.href?.includes("/video/")
                ? ""
                : url?.split("/")?.[4] && <BreadCrumb />}
            </>
          )}
          {/* <HelperScript argSmall={253} argLarge={0} /> */}
          {window.innerWidth < 991 ? (
            <div
              className="ad-section  ad-placeholder"
              style={{ width: "300px", height: "100px" }}
            >
              <InjectScript key={Math.random()} script={headerMobile} />
            </div>
          ) : (
            ""
          )}

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />

            <Route exact path="/video/:url" element={<VideoCategoryPage />} />
            {/* <Route exact path="/login" element={<Login />} /> */}
            {/* <Route exact path="/world" element={<World />} /> */}
            <Route
              exact
              path="/gujarat/metro"
              element={<GujaratMetro type="location" />}
            />
            <Route
              exact
              path="/gujarat/samagra"
              element={<GujaratSamagra type="location" />}
            />

            <Route
              exact
              path="/ganesh-chaturthi/:url"
              element={<GaneshChaturthiPage type="category" />}
            />

            <Route exact path="/tag/:url" element={<Gujarat type="tag" />} />
            <Route
              exact
              path="/"
              element={<AssemblyElection type="category" />}
            />
            <Route
              exact
              path="/assembly-elections"
              element={<AssemblyElection type="category" />}
            />
            <Route exact path="/:url" element={<Gujarat type="category" />} />
            <Route exact path="/location" element={<Gujarat type="location" />}>
              <Route
                exact
                path=":country"
                element={<Gujarat type="location" />}
              >
                <Route
                  exact
                  path=":state"
                  element={<Gujarat type="location" />}
                >
                  <Route
                    exact
                    path=":city"
                    element={<Gujarat type="location" />}
                  />
                </Route>
              </Route>
            </Route>
            <Route exact path="/epaper" element={<Epaper />} />
            <Route exact path="/epaper/sub" element={<ClippedImage />} />
            <Route
              exact
              path="/epaper/:city"
              element={<EpaperCategoryNewsPerDate />}
            />
            <Route exact path="/video" element={<VideosComponent />} />
            <Route exact path="/videos" element={<VideosComponent />} />
            {/* <Route exact path="/:url" element={<VideosInnerComponent />} /> */}
            <Route exact path="/page/:id" element={<Pages />} />
            <Route
              exact
              path="/investor-relations"
              element={<PagesInvestorRelation />}
            />
            <Route exact path="/gallery" element={<GalleryComponent />} />
            <Route exact path="/page/rss" element={<Rss />} />
            <Route exact path="/page/sitemap" element={<Sitemap />} />
            {sport === "2" ? (
              <Route
                exact
                path="/t20-world-cup"
                element={<Sport type="category" />}
              />
            ) : (
              ""
            )}
            {/* <Route exact path="/gallery/inner/:url" element={<GalleryInnerComponents />} /> */}
            <Route exact path="/astrology" element={<AstrologyComponent />} />
            <Route exact path="/supplement" element={<Supplement />} />
            {/* <Route exact path="/astrology/inner" element={<AstrologyInner />} /> */}
            {/* <Route exact path="/location" element={<Location />} /> */}
            <Route exact path="/search" element={<SearchResult />} />
            {/* <Route exact path="/profile" element={<Profile />} /> */}
            {localStorage.getItem("access_token") && (
              <Route exact path="/profile" element={<Profile />} />
            )}
            <Route exact path="/maintenance" element={<Maintenance />} />
            {/* <Route path="/:url" element={<Post />} /> */}

            {[
              "/:path1",
              "/:path1/:path2",
              "/:path1/:path2/:path3",
              "/:path1/:path2/:path3/:path4",
              "/:path1/:path2/:path3/:path4/:path5",
              "/:path1/:path2/:path3/:path4/:path5/:path6",
              "/:path1/:path2/:path3/:path4/:path5/:path6/:path7",
            ].map((path) => (
              <Route path={path} element={<Post />} />
            ))}
          </Routes>
          <ScrollButton />
          <Footer mode={mode} />
          {/* <InjectScript script={allPage} /> */}
          {/* {window.location.pathname.includes('epaper') ? <InjectScript script={bottomFooterScript} /> : ''} */}
          {/* {window.location.pathname === "/" ? <><InjectScript script={homePageScript2nd} /><InjectScript script={homePageScript4th} /></>: ''} */}
          {window.location.pathname === "/" ? (
            <>
              <InjectScript script={homePageScript2nd} />
            </>
          ) : (
            ""
          )}
          {/* {!url?.includes('/epaper/sub') && <Footer mode={mode} />} */}
        </Suspense>
      </Router>
    </>
  );
}

export default App;
